self.addEventListener('push', function (e) {
    if (!(self.Notification && self.Notification.permission === 'granted')) {
        //notifications aren't supported or permission not granted!
        return;
    }

    if (e.data) {
        var msg = e.data.json();
        e.waitUntil(self.registration.showNotification(msg.title, {
            body    : msg.body,
            icon    : msg.icon,
            actions : msg.actions
        }));
    }
});

self.addEventListener('notificationclick', function (e) {
    if (e.action && e.action !== "") {
        self.clients.openWindow(e.action)
    } else {
        self.clients.openWindow(e.notification.actions?.at(0)?.action ?? '/')
    }

})