import greek from '../lang/el.json';
import english from '../lang/en.json';

function __(key) {
    if (this.$page.props.locale === 'en') {
        return english[key] ?? key;
    }

    if (this.$page.props.locale === 'el') {
        return greek[key] ?? key;
    }

    return key;
}

export {__}