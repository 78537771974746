import './bootstrap';
import "../../resources/colorlib/css/style.css"
import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { __ } from './lang-handler';
import "./enable-push.js";

import { createApp, h, defineAsyncComponent } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';

const Button = defineAsyncComponent(() => import('primevue/button'));
const InputText = defineAsyncComponent(() => import('primevue/inputtext'));
const ConfirmDialog = defineAsyncComponent(() => import('primevue/confirmdialog'));
const Dialog = defineAsyncComponent(() => import('primevue/dialog'));

import PrimeVue from 'primevue/config';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';

createInertiaApp({
    // title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .mixin({methods : {route}})
            .mixin({ methods: { __ } })
            .use(PrimeVue, {ripple : true})
            .use(ToastService)
            .use(ConfirmationService)
            .component('router-link', {})
            .component('ConfirmDialog', ConfirmDialog)
            .component('Dialog', Dialog)
            .component('InputText', InputText)
            .component('Button', Button)
            .directive('ripple', Ripple)
            .directive('styleclass', StyleClass)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
