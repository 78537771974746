'use strict';

window.swReady = navigator?.serviceWorker?.ready ?? false;
import "./sw.js";

document.addEventListener('DOMContentLoaded', function () {
    initSW();
});

function initSW() {
    if (!"serviceWorker" in navigator) {
        //service worker isn't supported
        return;
    }

    //don't use it here if you use service worker
    //for other stuff.
    if (!"PushManager" in window) {
        //push isn't supported
        return;
    }

    //register the service worker
    navigator.serviceWorker.register('/sw.js')
        .catch((err) => {
            console.log(err)
        });
}
